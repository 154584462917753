import React from 'react';
import BootstrapGrid from "../../../generic-components/components/BootstrapGrid/BootstrapGrid";
import {footerPatternRebuilder} from './footer.rebuilder.util';
import Wrapper from "../../../generic-components/components/Containers/Wrapper";
import {useDispatch, useSelector} from "react-redux";
import FooterSection from "./FooterSection";
import _ from 'lodash';
import ContactForm from "./forms/ContactForm";
import NewsLetterForm from "./forms/NewsLetterForm";
import {Link} from "react-router-dom";
import ACTION_TYPES from "../../../Redux/ACTION_TYPES";

const Footer = () => {

    const footer = useSelector(state => state?.labels?.footer);
    const footerSections = _.get(footer, 'sections', []);

    const contactFooterSection = _.find(footerSections, section => section?.name === 'Contact');
    const NewsletterFooterSection = _.find(footerSections, section => section?.name === 'newsletter');
    const creditsFooterSection = _.find(footerSections, section => section?.name === 'Credits');

    const dispatch = useDispatch();

    return footer ?
        <Wrapper {...{extraClassName: 'footer-section', tag: 'footer', id: 'fb-footer-section'}}>
            <BootstrapGrid {...{
                extraClassName: 'container',
                rebuildedPatternUtil: footerPatternRebuilder
            }}>

                <FooterSection {...{footerSection: contactFooterSection, tailJsx: <ContactForm/>}}/>
                <div>
                    <FooterSection {...{footerSection: NewsletterFooterSection, tailJsx: <NewsLetterForm/>}}/>
                    <FooterSection {...{
                        footerSection: creditsFooterSection,
                        extraClassName: 'fb-credits-footer-section'
                    }}/>
                </div>
            </BootstrapGrid>
            <hr/>
            <div className={'fb-disclaimer'}>
                <Link onClick={() => dispatch({
                    type: ACTION_TYPES.LABELS.NAVIGATION.ACTIVATE_NAVIGATION_TAB,
                    payload: {pageId: -1}
                })} to={'/disclaimer'}>GDPR disclaimer</Link>
            </div>
        </Wrapper> : null
};

export default Footer;
