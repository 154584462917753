import _ from 'lodash';

export const setPageLabels = (state, payload) => {

    const newState = _.cloneDeep(state) || {};
    const pageLabels = payload?.data;

    const newPage = {
        id: pageLabels?.id,
        name: pageLabels?.name,
        slug: pageLabels?.slug,
        isPublished: pageLabels?.isPublished,
        showNewsFeed: pageLabels?.showNewsfeed
    };


    reduceQuicklinks(newPage, pageLabels?.quicklinks);
    reduceThemeImg(newPage, pageLabels?.pageThemeImage);
    reduceBlogs(newPage, pageLabels?.blogs);

    const updatedPages = [...newState.pages || [], newPage];
    newState.pages = updatedPages;

    return newState;
};

const reduceThemeImg = (newPage, pageThemeLabels) => {
    const newThemeImg = newPage.themeImg = {};

    newThemeImg.url = pageThemeLabels?.url;
    newThemeImg.alt = pageThemeLabels?.alternativeText;
}

export const reduceQuicklinks = (newPage, quicklinksLabels) => {
    const newQuicklinks = newPage.quicklinks = [];

    _.forEach(quicklinksLabels, quicklinkLabels => {
        newQuicklinks.push({
            pageId: quicklinkLabels?.page,
            title: quicklinkLabels?.name
        })
    });
};

export const reduceBlogs = (newPage, blogsLabels) => {
    const newBlogs = newPage.blogs = [];
    _.forEach(blogsLabels, blogLabels => {
        newBlogs.push({
            name: blogLabels?.name,
            articles: blogLabels?.blog_articles,
        });
    });
};
