import {compose, createStore, applyMiddleware} from "redux";
import rootReducer from "../Reducers";
import {setEnvironment} from '../actions/appSettings.action';
import thunk from 'redux-thunk';

const getStore = () => {
    const enhancers = compose(
        applyMiddleware(thunk),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    );

    let preLoadedState = {};
    const store = createStore(rootReducer, preLoadedState, enhancers);

    store.dispatch(setEnvironment());

    return store;
};

export default {
    getStore
};
