import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import FORM_CONSTANTS from "./FORM_CONSTANTS";
import axios from "axios";
import {Slide, toast, ToastContainer} from "react-toastify";
import {useSelector} from "react-redux";
import _ from 'lodash';
import 'react-toastify/dist/ReactToastify.css';
import {getBackendServerUrl} from "../../../utils/routing.util";

const toastOptions = {
    position: "bottom-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    transition: Slide
};

const notifySuccess = () => toast.success("Succesvol verzonden", toastOptions);
const notifyFail = () => toast.error("Er ging iets mis met het verzenden van het formulier.", toastOptions);

const Form = ({config}) => {
    const [submitIsPending, setSubmitIsPending] = useState(false);
    const [formValues, setFormValues] = useState({});

    const formIsValid = useMemo(() => !_.isEmpty(Object.values(formValues)) && Object.values(formValues).every(field => !!field?.isValid), [formValues]);

    const {CHECK_BOX} = FORM_CONSTANTS.INPUT_TYPES;
    const formRef = useRef(null);

    const appEnv = useSelector(state => state.appSettings.environment);

    const wipeForm = () => {
        setFormValues({});
        formRef.current.reset();
    };
    const resetForm = useCallback(() => {
        setSubmitIsPending(false);
        wipeForm();
    }, []);

    const sendForm = useCallback(() => {
        setSubmitIsPending(true);
        const url = getBackendServerUrl(appEnv, config.SUBMIT.ROUTE);
        axios({
            method: "POST",
            url,
            data: {...formValues}
        }).then((response) => {
            notifySuccess();
            resetForm();
        }).catch(() => {
            notifyFail();
            resetForm();
        });
    }, [config, formValues, appEnv, resetForm]);

    const validateAllFields = () => {
        config.ALL_INPUT_FIELDS.forEach(FIELD => {
            const {key, validator} = FIELD;
            const errorMessages = [];
            setFormValues(prev => {
                return ({
                    ...prev || [],
                    [key]: {
                        ..._.get(prev, key, {}),
                        isValid: validator(_.get(prev, `${key}.input`), errorMessages),
                        errorMessages
                    }
                })
            });
        });
    }

    const handleSubmit = e => {
        e.preventDefault();
        validateAllFields();
    }
    const handleChange = (e, inputKey, inputType, validator) => {
        e.persist();
        const input = _.get(e, FORM_CONSTANTS.INPUT_TYPE_TO_EVENT_PATH_MAPPER[inputType]);
        console.log('{e, inputKey, inputType, validator}', {e, inputKey, inputType, validator});
        setFormValues(prev => ({
            ...prev,
            [inputKey]: {
                ...prev[inputKey],
                input
            }
        }))
    };

    useEffect(() => {
        formIsValid && sendForm();
    }, [formIsValid, sendForm])

    return <form className={`${config.key} fb-form`} onSubmit={handleSubmit} ref={formRef}>
        <h2>{config.TITLE}</h2>

        {config.ALL_INPUT_FIELDS.map(FIELD => {
            const {label, key, type, tag: Tag} = FIELD;
            const isValid = !!formValues[FIELD.key]?.isValid;
            const errorMessages = _.get(formValues, `${FIELD.key}.errorMessages`, []);

            return <div className={'fb-label-input row'}>
                {!!label && <div className={'col-12'}>
                    <label htmlFor={key}>
                        {type !== CHECK_BOX && label}
                        {!isValid && <div className={'fb-error-message'}>{errorMessages[0]}</div>}
                        <Tag
                            {...{
                                className: key,
                                onChange: e => handleChange(e, FIELD.key, FIELD.type, FIELD.validator),
                                onSelect: e => handleChange(e, FIELD.key, FIELD.type, FIELD.validator),
                                id: key,
                                type
                            }}
                        />
                        {type === CHECK_BOX && label}
                    </label>
                </div>}
            </div>
        })}

        <div className={'submit-row'}>
            <div className={'fb-submit-row col-12'}>
                <input disabled={submitIsPending} onSubmit={handleSubmit}
                       className={`${config.SUBMIT.KEY} fb-form-submit ${submitIsPending && 'disabled'}`}
                       type="submit"
                       value={submitIsPending ? config.SUBMIT.SUBMIT_IS_PENDING_LABEL : config.SUBMIT.SUBMIT_LABEL}/>
            </div>
        </div>
        <ToastContainer {...toastOptions}/>
    </form>
};

export default Form;
