export const footerPatternRebuilder = {
    biggestBasicPattern: 3,
    getPattern: amountOfItems => {
        let toReturn = [];

        switch (amountOfItems) {
            case 1:
                toReturn.push('col-12');
                break;
            case 2: {
                toReturn = Array(2).fill('col-12 col-md-6');
                break;
            }
            case 3: {
                toReturn = Array(3).fill('col-12 col-md-4');
                break;
            }
            default:
                break;
        }

        return toReturn && toReturn.length > 0 ? toReturn : null;
    }
};