import _ from "lodash";
import Picture from "../../Picture/Picture";
import React, {useEffect, useRef, useState} from "react";
import {getCorrectlySpacedString} from "../../../utils/componentTyping.util";
import _dom from '../../../utils/domManipulation.util';
import {BUTTON_TYPES} from "../../Button/BUTTON_TYPES";
import Button from "../../Button/Button";

//TODO this should be "CollapsableArticle"; create specific Article-COMPONENT that is NOT collapsible!

const Article = ({article, extraClassName, enableReadMoreLessFunc, ctaLabels, onCollapseCallback}) => {


    const [spaceTaken, setSpaceTaken] = useState(0);
    const [spaceNeeded, setSpaceNeeded] = useState(0);
    const [hasTooMuchText, setHasTooMuchText] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);

    const {figure, content } = article;
    const {src, figCaption, alt, figureType} = _.pick(figure, ['src', 'figCaption', 'figureType', 'figureType']);


    const {readMore, readLess} = _.get(ctaLabels, 'buttonLabels', {});

    const articleClassName = getCorrectlySpacedString(extraClassName, 'article');

    const articleRef = useRef(null);

    useEffect(() => {
        const spaceTaken = _dom.getDimensions(articleRef.current).height;
        setSpaceTaken(prev => prev ? prev : spaceTaken);
    }, []);
    useEffect(() => {
        const spaceNeeded = _dom.getDimensionChildren(articleRef.current).height;
        setSpaceNeeded(prev => prev ? prev : spaceNeeded - 1);
    }, []);
    useEffect(() => setHasTooMuchText(() => {
        return spaceNeeded > spaceTaken;
    }), [spaceNeeded, spaceTaken]);

    return <article id={article?.name} ref={articleRef} {...{className: articleClassName}}>


        {src ? <figure>
            <Picture {...{
                src,
                type: figureType,
                alt,
                extraClassName: `${extraClassName}-img`
            }}/>
            {figCaption ? <figcaption>{figCaption}</figcaption> : null}
        </figure> : null}

        <div className='article-content' dangerouslySetInnerHTML={{__html: content}}></div>

        {enableReadMoreLessFunc && hasTooMuchText ?
            <span className={getCorrectlySpacedString('read-more-container')}><Button {...{
                type: BUTTON_TYPES.ANCHOR,
                extraClassName: 'read-more-or-less-button',
                label: isCollapsed ? readMore : readLess,
                onClick: () => {
                    setIsCollapsed(prev => {
                        const isCurrentlyCollapsed = !prev;
                        onCollapseCallback(isCurrentlyCollapsed ? null : spaceNeeded);
                        return isCurrentlyCollapsed;
                    });
                }
            }}/></span> : null}

    </article>;
};

export default Article;