const CONSTANTS = {
	BREAKPOINTS: {
		BOOTSTRAP_BREAKPOINTS: {
			XS: {
				MAX_WIDTH: 575,
				NAME: 'XS'
			},
			SM: {
				MAX_WIDTH: 767,
				NAME: 'SM'
			},
			MD: {
				MAX_WIDTH: 991,
				NAME: 'MD'
			},
			LG: {
				MAX_WIDTH: 1199,
				NAME: 'LG'
			},
			XL: {
				MAX_WIDTH: 2500,    //arbitrary
				NAME: 'XL'
			}
		}
	},
	CSS_CLASSES_KEY: 'CORRESPONDING_CSS_NAMES',
	HTML_TAG_KEY: 'CORRESPONDING_HTML_TAG',
};

export default CONSTANTS;