import React from 'react';
import Wrapper from "../../../generic-components/components/Containers/Wrapper";
//import Blog from "../../../../../../../../generic-components/Sections/TextAndImage/Blog";

const NewsArticle = () => {
	return <Wrapper {...{extraClassName: 'fb-news-article'}}>
		{/*<Blog {...{}}/>*/}
	</Wrapper>;
};

export default NewsArticle;