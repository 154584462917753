import React from 'react';
import Picture from "../../../generic-components/components/Picture/Picture";
import _ from "lodash";
import BlogSection from "../../partials/Blog/BlogSection";

const MainThemedBlogSection = ({themeImg, blogs}) => {
    return <div className='fb-flow-breaker'>
        {themeImg?.url ? <Picture {...{
            src: themeImg?.url,
            alt: themeImg?.alt,
            extraClassName: 'fb-page-picture',
        }}/> : null}

        {!_.isEmpty(blogs) ? blogs.map(blog => <div key={blog.name}>
            <BlogSection {...{
                blog,
                rootHeadingNr: 1,
                extraClassName: 'fb-homepage-intro'
            }}/>
        </div>) : null}
    </div>
};

export default MainThemedBlogSection;
