import React from 'react';
import {getCorrectlySpacedStringFromType} from "../../utils/componentTyping.util";
import PropTypes from "prop-types";
import CONSTANTS from "../../CONSTANTS/CONSTANTS";

const Wrapper = ({extraClassName, children, type, tag, ref, height, id, keyProp}) => {

    const className = getCorrectlySpacedStringFromType('wrapper', type, extraClassName);
    //TODO: this kind of code lines should be dry-'ed up

    const Tag = tag || 'div';   //TODO you can pass tag: 'blobbers' and you'd get <blobbers/> :s

    return <Tag key={keyProp} id={id} ref={ref} className={className} style={height ? {height: `${height}px`} : {}}>
        {children}
    </Tag>
};

Wrapper.propTypes = {
    extraClassName: PropTypes.string.isRequired,
    type: PropTypes.shape({
        [CONSTANTS.CSS_CLASSES_KEY]: PropTypes.arrayOf(PropTypes.string)
    }),
    children: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired,
        PropTypes.node.isRequired
    ]).isRequired,
    tag: PropTypes.string
};


/*Wrapper.defaultProps = {
	type: TYPES.COVERED.COVERED_WIDTH_AND_HEIGHT
};*/

export default Wrapper;
