import ACTION_TYPES from '../../ACTION_TYPES';
import {setNavigationLabels, activateNavigationTab} from "./navigation/labels.navigation.reducer";
import {setPageLabels} from "./pages/labels.pages.reducer";
import {setFooterLabels} from "./footer/labels.footer.reducers";

export default function (state, action) {
    switch (action.type) {
        case ACTION_TYPES.LABELS.PAGES.SET_PAGE_LABELS:
            return setPageLabels(state, action?.payload);
        case ACTION_TYPES.LABELS.NAVIGATION.SET_NAVIGATION_LABELS:
            return setNavigationLabels(state, action?.payload);
        case ACTION_TYPES.LABELS.NAVIGATION.ACTIVATE_NAVIGATION_TAB:
            return activateNavigationTab(state, action?.payload);
        case ACTION_TYPES.LABELS.FOOTER.SET_FOOTER_LABELS:
            return setFooterLabels(state, action?.payload);
        default:
            return state || {}
    }
}
