import {validatorChainer, isEmail, hasValue} from '../../Form/utils/validators.util';
import FORM from '../../Form/FORM_CONSTANTS';
import {CONSTANTS} from "../../../../CONSTANTS";

const {CONTACT_FORM} = CONSTANTS.ROUTING.BACKEND_SERVER;


export default {
    TITLE: 'Contactformulier',
    key: 'fb-contactform',
    INPUT_FIELDS: {
        EMAIL: {
            key: 'fb-contact-form-email-input',
            label: 'Je mailadres:',
            type: FORM.INPUT_TYPES.TEXT,
            validator: validatorChainer(hasValue, isEmail),
            get tag() {
                return FORM.inputsWithInputTag.find(input => input === this.type) ? 'input' : this.type;
            }
        },
        FEEDBACK: {
            key: 'fb-contact-form-feedback-input',
            label: 'Je opmerking:',
            type: FORM.INPUT_TYPES.TEXT_AREA,
            validator: validatorChainer(hasValue),
            get tag() {
                return FORM.inputsWithInputTag.find(input => input === this.type) ? 'input' : this.type;
            }
        },
        SUBSCRIBE_TO_NEWSFORM: {
            key: 'fb-subscribe-to-newsletter-checkbox',
            label: 'Ik wens me in te schrijven op de nieuwsbrief',
            type: FORM.INPUT_TYPES.CHECK_BOX,
            validator: validatorChainer(),
            get tag() {
                return FORM.inputsWithInputTag.find(input => input === this.type) ? 'input' : this.type;
            }
        }
    },
    SUBMIT: {
        SUBMIT_IS_PENDING_LABEL: 'Verzenden...',
        SUBMIT_LABEL: 'Bevestigen',
        KEY: 'fb-contact-form-submit',
        ROUTE: CONTACT_FORM
    },
    get ALL_INPUT_FIELDS() {
        return Object.values(this.INPUT_FIELDS);
    }
};
