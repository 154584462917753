import React, {useEffect} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {useSelector, useDispatch} from "react-redux";
import Navigation from "../partials/Navigation/navigation";
import GenericPage from "../pages/MainGenericPage/GenericPage";
import Footer from "../partials/Footer/Footer";
import {CONSTANTS} from "../../CONSTANTS";
import ScrollToHashIdOnMount from "./ScrollToHashIdOnMount";
import {getFooterLabels} from '../../Redux/actions/labels.action';
import {getNavigationLabels} from '../../Redux/actions/navigation.action';
import {activateNavigationTab} from '../../Redux/actions/navigation.action';
import {getCurrentPage} from '../../utils/routing.util';

const RoutedApplication = () => {
    const firstPageHasFinishedLoading = useSelector(state => state?.appSettings?.loading?.firstPageHasFinishedLoading);
    const dispatch = useDispatch();

    const appEnv = useSelector(state => state?.appEnv?.environment);
    const currentPage = getCurrentPage();

    useEffect(() => {
        dispatch(getFooterLabels(appEnv));
        dispatch(getNavigationLabels(appEnv))
            .then(() => dispatch(activateNavigationTab(currentPage.id)));
    }, [dispatch, currentPage, appEnv]);


    return <Router>
        <Navigation/>
        <Switch>
            {CONSTANTS.ALL_PAGES.map(PAGE => <Route key={PAGE.id} exact path={PAGE.slug}>
                <GenericPage {...{
                    hardcodedPage: PAGE,
                    extraClassName: `fb-${PAGE.name}`
                }}/></Route>)}

            <Route><Redirect to={CONSTANTS.HARDCODED_PAGES.HOMEPAGE.slug}/></Route>
        </Switch>
        <Footer/>
        {firstPageHasFinishedLoading && <ScrollToHashIdOnMount/>}
    </Router>;
};

export default RoutedApplication;
