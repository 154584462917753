import React from 'react';
import Section from "../../../../generic-components/components/Sections/Sections";
import Form from "../../Form/form";
import NEWSLETTER_FORM_CONFIG from './newsletterForm.config';

const NewsLetterForm = () => {
    return <Section extraClassName={'fb-contact-form-section'}>
        <Form {...{config: NEWSLETTER_FORM_CONFIG}}></Form>
    </Section>
};

export default NewsLetterForm;
