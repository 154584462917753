export default {
    LABELS: {
        NAVIGATION: {
            SET_NAVIGATION_LABELS: 'set-navigation-labels',
            ACTIVATE_NAVIGATION_TAB: 'activate-navigation-tab'
        },
        PAGES: {
            SET_PAGE_LABELS: 'set-page-labels'
        },
        FOOTER: {
            SET_FOOTER_LABELS: 'set-footer-labels'
        }
    },
    APP_SETTINGS: {
        SET_ENVIRONMENT: 'set-environment',
        LOADING: {
            SET_FIRST_PAGE_HAS_LOADED: 'set-first-page-has-loaded'
        }
    }
}
