import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styling/_index.scss';
/*import netlifyIdentity from 'netlify-identity-widget';*/

/*window.netlifyIdentity = netlifyIdentity;*/
// You must run this once before trying to interact with the widget
/*netlifyIdentity.init();*/

ReactDOM.render(<App/>, document.getElementById('root'));
