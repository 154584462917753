import ACTION_TYPES from "../ACTION_TYPES";
import axios from 'axios';
import {getContentServerUrl} from "../../utils/routing.util";
import {CONSTANTS} from "../../CONSTANTS";

const {PAGE_LABELS, FOOTER_LABELS} = CONSTANTS.ROUTING.CONTENT_SERVER;

export const getCurrentPageLabels = (env, page) => {
    return dispatch => {
        return axios.get(`${getContentServerUrl(env, PAGE_LABELS)}/${page?.id}`)
            .then(result => dispatch({
                type: ACTION_TYPES.LABELS.PAGES.SET_PAGE_LABELS,
                payload: result
            }))
    }
};

export const getFooterLabels = (env) => {
    return dispatch => {
        return axios.get(getContentServerUrl(env, FOOTER_LABELS))
            .then(result => dispatch({
                type: ACTION_TYPES.LABELS.FOOTER.SET_FOOTER_LABELS,
                payload: result
            }))
    }

};
