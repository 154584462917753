import React from 'react';
import Section from "../../../../generic-components/components/Sections/Sections";
import CONTACT_FORM_CONFIG from './contactForm.config';
import Form from '../../Form/form';

const ContactForm = () => {
    return <Section extraClassName={'fb-contact-form-section'}>
        <Form {...{config: CONTACT_FORM_CONFIG}}></Form>
    </Section>
};

export default ContactForm;
