import _ from "lodash";
import {CONSTANTS} from "../../../../CONSTANTS";

const hostToEnvMapper = {
    [CONSTANTS.HOSTING.WEBSITE.DEV_HOST]: CONSTANTS.ENVIRONMENTS.DEVELOPMENT,
    [CONSTANTS.HOSTING.WEBSITE.PROD_HOST]: CONSTANTS.ENVIRONMENTS.PRODUCTION
}

export const setEnvironment = (state, payload) => {
    const newState = _.cloneDeep(state) || {};

    newState.environment = hostToEnvMapper && hostToEnvMapper[payload];

    return newState;
}
