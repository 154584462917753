import React from 'react';
import Article from "./Article";
import Wrapper from "../../Containers/Wrapper";

const Blog = ({extraClassName, blog, rootHeadingNr}) => {
    return <Wrapper {...{extraClassName: 'fb-blog'}}>
        <Wrapper {...{extraClassName: 'fb-blog-text-area'}}>
            {blog?.articles.map((article, index) => <div key={article.name}>
                <Article {...{
                    article
                }}/>
                {index !== blog?.articles?.length - 1 ? <hr/> : null}
            </div>)}
        </Wrapper>
    </Wrapper>;
};

export default Blog;
