export default {
    INPUT_TYPES: {
        TEXT: 'text',
        TEXT_AREA: 'textarea',
        CHECK_BOX: 'checkbox'
    },
    ERROR_MESSAGES: {
        EMAIL: {
            GENERIC: 'Please, provide a valid email'
        },
        REQUIRED: {
            GENERIC: '*'
        }
    },
    get TYPE_TO_EVENT_PATH_MAPPER() {
        return ({
            [this.INPUT_TYPES.TEXT_AREA]: 'target.value',
            [this.INPUT_TYPES.TEXT]: 'target.value',
            [this.INPUT_TYPES.CHECK_BOX]: 'target.checked'
        });
    },
    get INPUT_TYPE_TO_EVENT_PATH_MAPPER() {
        return ({
            [this.INPUT_TYPES.CHECK_BOX]: 'target.checked',
            [this.INPUT_TYPES.TEXT]: 'target.value',
            [this.INPUT_TYPES.TEXT_AREA]: 'target.value'
        });
    },
    get inputsWithInputTag() {
        return [this.INPUT_TYPES.TEXT, this.INPUT_TYPES.CHECK_BOX]
    }
}
