import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link} from "react-router-dom";
import _ from 'lodash';
import ACTION_TYPES from "../../../Redux/ACTION_TYPES";
import {CONSTANTS} from "../../../CONSTANTS";

const Navigation = () => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const labels = useSelector(state => _.get(state, 'labels'));
    const navigation = _.get(labels, 'navigation');
    const tabs = navigation?.tabs;
    const visibleTabs = tabs?.filter(tab => tab.isPagePublished);
    const homepageTab = tabs?.find(tab => tab?.slug === '/');
    const dispatch = useDispatch();

    return navigation ? <nav className="navbar navbar-expand-lg">

        <Link onClick={() => dispatch({
            type: ACTION_TYPES.LABELS.NAVIGATION.ACTIVATE_NAVIGATION_TAB,
            payload: {pageId: CONSTANTS.HARDCODED_PAGES.HOMEPAGE.id}
        })} className="navbar-brand" {...{to: `${homepageTab?.slug}`}}>{homepageTab?.title}</Link>

        <button aria-label='Open the menu' onClick={() => setIsCollapsed(prev => !prev)} className="navbar-toggler" type="button">
            <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`collapse navbar-collapse ${isCollapsed ? '' : ' show'}`}>
            <ul className="navbar-nav">
                {!_.isEmpty(visibleTabs) ? visibleTabs.filter(tab => tab?.slug !== '/').map(tab => {

                    const isActive = tab?.isActive;
                    const label = tab?.title;
                    const slug = tab?.slug;

                    return <li key={tab?.title} onClick={() => dispatch({
                        type: ACTION_TYPES.LABELS.NAVIGATION.ACTIVATE_NAVIGATION_TAB,
                        payload: {pageId: tab?.pageId}
                    })} className={`nav-item ${isActive ? 'active' : ''}`}>
                        <Link
                            className={`nav-link ${tab?.isActive ? 'active' : ''}`} {...{to: `${slug}`}}>{label}</Link>
                    </li>
                }) : null}
            </ul>
        </div>
    </nav> : null
};

export default Navigation;
