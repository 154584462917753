import React from 'react';
import Wrapper from "../../../../generic-components/components/Containers/Wrapper";
import TYPES from "../../../../generic-components/CONSTANTS/TYPES";
import {Link} from "react-router-dom";
import Picture from "../../../../generic-components/components/Picture/Picture";
import {getComposedType} from "../../../../generic-components/CONSTANTS/types.util";

const NavigationCard = ({title, routePath, img, extraClassName}) => {
    return <Link {...{to: routePath}}>
        <Wrapper {...{extraClassName: 'fb-navigation-card', type: TYPES.POSITIONED.RELATIVE}}>

            <Wrapper {...{extraClassName: 'fb-navigation-card-banner'}}>
                <Picture {...{type: TYPES.COVERED.COVERED_WIDTH_AND_HEIGHT, src: img?.url, alt: img?.alt}}/>
                <Wrapper {...{
                    extraClassName: 'fb-navigation-card-text-area',
                    type: getComposedType(TYPES.POSITIONED.ABSOLUTE.VERTICALLY_CENTERED, TYPES.COVERED.COVERED_WIDTH)
                }}>
                    <h1>{title}</h1>
                </Wrapper>
            </Wrapper>
        </Wrapper>
    </Link>
};

export default NavigationCard;