import React from 'react';
import Section from "../../../generic-components/components/Sections/Sections";
import Wrapper from "../../../generic-components/components/Containers/Wrapper";
import NavigationCard from "./NavigationCard/NavigationCard";
import {useSelector} from "react-redux";
import _ from 'lodash';

const QuicklinkNavigation = ({quicklinks}) => {
    const pages = useSelector(state => state?.labels?.pages);
    const pagesArr = pages && Object.values(pages);
    const bootstrapClass = 'col-12 col-md-6 col-lg-4';

    return <Section {...{extraClassName: 'fb-quicklinks-navigation container-fluid'}}>
        <Wrapper extraClassName='row justify-content-center'>
            {!_.isEmpty(quicklinks) ? quicklinks.map(quicklink => {
                const associatedPage = pagesArr.find(page => page?.id === quicklink?.pageId);
                return associatedPage?.isPublished ?
                    <Wrapper {...{extraClassName: bootstrapClass, keyProp: quicklink.pageId}}>
                        <NavigationCard key={quicklink?.title} {...{
                            title: quicklink?.title,
                            routePath: associatedPage?.slug,
                            img: associatedPage?.themeImg
                        }}/>
                    </Wrapper> : null
            }) : null}
        </Wrapper>
    </Section>
};

export default QuicklinkNavigation;
