import React from 'react';
import _ from "lodash";
import {svgMapper, anchorTypeMapper} from './iconMapper';
import classNames from 'classnames';

const FooterSection = ({footerSection, tailJsx, extraClassName}) => {
    const getHrefIfNeeded = (Tag, item) => Tag === 'a' ? {href: `${anchorTypeMapper[item.icon]}${item.value}`} : {};
    return <div className={classNames('fb-footer-footerSection', extraClassName)}>
        <h1>{footerSection?.title}</h1>
        <hr/>
        <ul>
            {!_.isEmpty(footerSection?.items) && footerSection?.items.map(item => {

                const isCTA = item?.value;
                const Tag = isCTA ? 'a' : React.Fragment;

                return <li key={item.title} className={'col-12'}>
                    <Tag {...getHrefIfNeeded(Tag, item)}>
                        {
                            item?.icon && <svg className="icon icon--facebook">
                                <use xlinkHref={`#${svgMapper[item.icon]}`} href={`#${svgMapper[item.icon]}`}></use>
                            </svg>
                        }
                        {item?.title}
                    </Tag>
                </li>
            })}
        </ul>
        {tailJsx}
    </div>
};

export default FooterSection;
