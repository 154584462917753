import {useCallback, useEffect} from 'react';
import {getCurrentPageLabels} from "../../../Redux/actions/labels.action";
import {setFirstPageHasLoaded} from "../../../Redux/actions/appSettings.action";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {CONSTANTS} from "../../../CONSTANTS";

const SetUp = ({hardcodedPage}) => {
    const dispatch = useDispatch();
    const firstPageHasFinishedLoading = useSelector(state => state?.appSettings?.loading?.firstPageHasFinishedLoading);

    const pageLabels = useSelector(state => _.find(state?.labels?.pages, page => page.id === hardcodedPage.id));
    const navigationLabels = useSelector(state => state.labels.navigation);
    const footerLabels = useSelector(state => state.labels.footer);

    const appEnv = useSelector(state => state?.appSettings?.environment);

    //TODO: util!
    const getFastPagesLabels = useCallback(pageToExclude => {
        const FAST_PAGES = CONSTANTS.FAST_PAGES.filter(page => page.id !== pageToExclude.id);
        const promises = [];

        _.forEach(FAST_PAGES, page => promises.push(dispatch(getCurrentPageLabels(appEnv, page))));

        return Promise.all(promises);
    }, [dispatch, appEnv]);
    //

    useEffect(() => {
        !pageLabels && dispatch(getCurrentPageLabels(appEnv, hardcodedPage));
    }, [hardcodedPage, dispatch, pageLabels, appEnv]);
    useEffect(() => {
        const shouldToggleFirstPageLoad = !firstPageHasFinishedLoading && navigationLabels && pageLabels && footerLabels;
        if (shouldToggleFirstPageLoad) {
            dispatch(setFirstPageHasLoaded());
            getFastPagesLabels(hardcodedPage);
        }
    }, [navigationLabels, footerLabels, dispatch, firstPageHasFinishedLoading, getFastPagesLabels, hardcodedPage, pageLabels]);
    return null;
};

export default SetUp;
