import _ from 'lodash';

const getRebuildedPattern = (numberOfItems, rebuildPatternUtil) => {
	let toReturn = [];

	const {biggestBasicPattern, getPattern} = _.pick(rebuildPatternUtil, ['biggestBasicPattern', 'getPattern']);

	const amountOfBasicPatternRepeats = Math.ceil(numberOfItems / biggestBasicPattern);

	for (let i = 0; i < amountOfBasicPatternRepeats; i++) {
		const amount = numberOfItems >= biggestBasicPattern ? biggestBasicPattern : numberOfItems;
		const toPush = getPattern(amount);
		toReturn.push(...toPush);
		numberOfItems -= biggestBasicPattern;
	}

	return toReturn && toReturn.length > 0 ? toReturn : null;

};

export {getRebuildedPattern};