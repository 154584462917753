import ACTION_TYPES from "../ACTION_TYPES";

export const setEnvironment = () => ({
    type: ACTION_TYPES.APP_SETTINGS.SET_ENVIRONMENT,
    payload: window.location.host
})

export const setFirstPageHasLoaded = () => ({
    type: ACTION_TYPES.APP_SETTINGS.LOADING.SET_FIRST_PAGE_HAS_LOADED
});

