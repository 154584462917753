import ACTION_TYPES from "../ACTION_TYPES";
import axios from "axios";
import {getContentServerUrl} from "../../utils/routing.util";
import {CONSTANTS} from "../../CONSTANTS";

const {NAVIGATION_LABELS} = CONSTANTS.ROUTING.CONTENT_SERVER;

export const activateNavigationTab = pageId => ({
    type: ACTION_TYPES.LABELS.NAVIGATION.ACTIVATE_NAVIGATION_TAB,
    payload: {pageId}
});

export const getNavigationLabels = (env) => {
    return dispatch => {
        return axios.get(getContentServerUrl(env, NAVIGATION_LABELS))
            .then(result => dispatch({
                type: ACTION_TYPES.LABELS.NAVIGATION.SET_NAVIGATION_LABELS,
                payload: result
            }));
    }
}
