import React from 'react';
import {getCorrectlySpacedString} from "../../utils/componentTyping.util";
import {getRebuildedPattern} from "./bootstrapGrid.util";
import PropTypes from "prop-types";
import TYPES from "../../CONSTANTS/TYPES";
import {basicRebuilder1} from "./rebuildPatternUtils/basicRebuilder1.util";
import _ from 'lodash';
import classNames from 'classnames';

const BootstrapGrid = ({children, rebuildedPatternUtil, extraClassName}) => {

    const bootstrapClasses = getRebuildedPattern(children?.length, rebuildedPatternUtil);
    const className = getCorrectlySpacedString(getCorrectlySpacedString('bootstrap-grid', extraClassName));
    const getCellClassName = index => classNames('bootstrap-grid-cell', `bootstrap-grid-cell-${index + 1}`, bootstrapClasses[index]);


    return <div className={className}>
        <div className='row'>

            {!_.isEmpty(children) && children.map((child, index) => <div
                key={`bootstrap-col-${index + 1}`}
                className={getCellClassName(index)}>
                {child}
            </div>)}

        </div>
    </div>
};

BootstrapGrid.propTypes = {
    extraClassName: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired,
        PropTypes.node.isRequired
    ]),
    rebuildedPatternUtil: PropTypes.object
};


BootstrapGrid.defaultProps = {
    type: TYPES.COVERED.COVERED_WIDTH_AND_HEIGHT,
    rebuildedPatternUtil: basicRebuilder1
};

export default BootstrapGrid;
