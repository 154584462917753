import _ from "lodash";

export const setFooterLabels = (state, payload) => {
    const footerLabels = payload?.data;
    const newState = _.cloneDeep(state);
    const newFooter = newState.footer = {};

    newFooter.sections = footerLabels.footersecties;

    reduceFooterSections(newFooter, footerLabels?.footersecties);

    return newState;
};

const reduceFooterSections = (newFooter, footerSectionsLabels) => {
    const newSections = newFooter.sections = [];
    _.forEach(footerSectionsLabels, footerSectionLabels => {
        const newSection = {
            name: footerSectionLabels?.name,
            title: footerSectionLabels?.title,
        };

        reduceFooterSectionItems(newSection, footerSectionLabels?.items);

        newSections.push(newSection);
    })
};

const reduceFooterSectionItems = (newSection, itemsLabels) => {
    const newItems = newSection.items = [];

    _.forEach(itemsLabels, itemLabels => {
        newItems.push({
            icon: itemLabels?.icon,
            title: itemLabels?.title,
            value: itemLabels?.value
        });
    })
}
