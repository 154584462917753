export const basicRebuilder1 = {
	biggestBasicPattern: 2,
	getPattern: amountOfItems => {
		let toReturn = [];

		switch (amountOfItems) {
			case 1:
				toReturn.push('col-12');
				break;
			case 2: {
				toReturn = Array(2).fill('col-6');
				break;
			}
			default:
				break;
		}

		return toReturn && toReturn.length > 0 ? toReturn : null;
	}
};