import _ from 'lodash';
import FORM from '../FORM_CONSTANTS';

export function validatorChainer(...validators) {
    return (value, errorMessages) => {
        let isValid = true;
        _.forEach(validators, validator => {
            if (!validator(value, errorMessages)) {
                isValid = false;
            }
        });
        return isValid;
    }
};

export const hasValue = (value, errorMessages) => {
    const isValid = !!value && !_.isEmpty(value);


    if (!isValid) {
        errorMessages && errorMessages.push(FORM.ERROR_MESSAGES.REQUIRED.GENERIC);
    }

    return isValid;
};

export const isEmail = (value, errorMessages) => {
    const regex = /\w{2,}@\w{2,}\.\w+/;
    const match = value && value.match(regex);
    const isValid = !!_.get(match, '[0]', false);

    if (!isValid) {
        errorMessages && errorMessages.push(FORM.ERROR_MESSAGES.EMAIL.GENERIC);
    }

    return isValid;
};
