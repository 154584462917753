import ACTION_TYPES from '../../ACTION_TYPES';
import {setEnvironment} from "./environment/appSettings.environment.reducer";
import {setWebsiteDidLoad} from "./loadStatus/appSettings.loadStatus.reducer";

export default function (state, action) {
    switch (action.type) {
        case ACTION_TYPES.APP_SETTINGS.SET_ENVIRONMENT:
            return setEnvironment(state, action?.payload);
        case ACTION_TYPES.APP_SETTINGS.LOADING.SET_FIRST_PAGE_HAS_LOADED:
            return setWebsiteDidLoad(state);
        default:
            return state || {}
    }
}
