import CONSTANTS from "../CONSTANTS/CONSTANTS";
import _ from 'lodash';


/*export const getCorrectlySpacedString = function (...args) {
	const sentence = args.length > 0 && args[0] ? getCorrectlySpacedString(...args).split(' ') : [];
	const indentedClassname = sentence.map(word => `${CONSTANTS.INDENTATION}${word}`).join(' ');

	return indentedClassname || '';
};*/

export const getCorrectlySpacedString = function (...args) {          //args = 'className1 className2', 'className3' -> 'className1 className2 className3'
	const filteredArgs = args
		.filter(argument => !!argument && !_.isEmpty(argument));
	const reducedAndFilteredArgs = filteredArgs
		.reduce((reduced, current) => [...reduced, ...current.split(' ')], []);
	const trimmedArgs = reducedAndFilteredArgs
		.map(argument => `${argument.trim()}`);
	const words = trimmedArgs.join(' ');

	return words;
};

export const getCorrectlySpacedStringFromType = (genericClassName, type, customClassName) => {
	return getCorrectlySpacedString(genericClassName, ..._.get(type, CONSTANTS.CSS_CLASSES_KEY, []), customClassName);
};

export const getTagFromType = (type, fallback) => {
	return type?.[CONSTANTS.HTML_TAG_KEY] || fallback || 'div';
};