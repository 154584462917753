import React from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import Section from "./generic-components/components/Sections/Sections";
import storeInitializer from './Redux/store/storeInitializer';
import RoutedApplication from "./components/RoutedComponent/RoutedApplication";

const store = storeInitializer.getStore();

const App = () => {
	return <ReduxProvider {...{store}}>
		<Section {...{extraClassName: 'fb-website'}}>
			<RoutedApplication/>
		</Section>
	</ReduxProvider>;
};

export default App;
