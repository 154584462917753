import React, {useMemo} from "react";
import QuicklinkNavigation from "../QuicklinkNavigation/QuicklinkNavigation";
import NewsFeeds from "../../partials/NewsFeed/NewsArticle";
import Section from "../../../generic-components/components/Sections/Sections";
import {getCorrectlySpacedString} from "../../../generic-components/utils/componentTyping.util";
import _ from 'lodash';
import ScrollToTopOnMount from "../../RoutedComponent/ScrollToTopOnMount";
import {useSelector} from "react-redux";
import MainThemedBlogSection from "./MainThemedBlogSection";
import SetUp from "./SetUp";

const GenericPage = ({extraClassName, hardcodedPage}) => {

    const className = getCorrectlySpacedString('fb-page', extraClassName);
    const pageLabels = useSelector(state => _.find(state?.labels?.pages, page => page.id === hardcodedPage.id));
    const {quicklinks, themeImg, showNewsFeed} = _.pick(pageLabels, ['quicklinks', 'themeImg', 'showNewsFeed']);

    const blogs = useMemo(() => {
        return pageLabels?.blogs
    }, [pageLabels]);

    return <Section {...{extraClassName: className}}>
        <SetUp {...{hardcodedPage}}/>
        <MainThemedBlogSection {...{themeImg, blogs}}/>
        {!_.isEmpty(quicklinks) ? <QuicklinkNavigation {...{quicklinks}}/> : null}
        {showNewsFeed ? <NewsFeeds/> : null}
        <ScrollToTopOnMount/>
    </Section>;
};

export default GenericPage;
