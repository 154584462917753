import CONSTANTS from "../../CONSTANTS/CONSTANTS";

//const floatCssClasses = ['float'];

//const croppedPictureCssClasses = ['cropped-picture'];
//const centerCropped = [...croppedPictureCssClasses, 'cropped-picture-center'];

const ANCHOR = {
	[CONSTANTS.HTML_TAG_KEY]: 'a',

};

export const BUTTON_TYPES = {
	ANCHOR,
};