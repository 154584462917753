import {useEffect} from 'react';

const ScrollToHashIdOnMount = () => {


    useEffect(() => {
        const oldHash = window.location.hash;
        window.location.hash = '';

        setTimeout(() => {
            window.location.hash = oldHash;
        }, 0)
    }, []);

    return null;
};

export default ScrollToHashIdOnMount;
