import React from 'react';
import {getCorrectlySpacedString, getTagFromType} from "../../utils/componentTyping.util";
//import {BUTTON_TYPES} from "./BUTTON_TYPES";

const Button = ({label, extraClassName, onClick, type, isDisabled, href}) => {

	const className = getCorrectlySpacedString(extraClassName, 'button');
	const Tag = getTagFromType(type, 'button');

	return <Tag {...{className, onClick, isDisabled}}>
		{label}
	</Tag>
};

export default Button;

/*

export const Button = ({kind, label, disabled, classNames, onClick, tagType, children, href, target}) => {
	const CustomTag = tagType === BUTTON_TAG_TYPES.ANCHOR ? `a` : `button`;

	return <CustomTag {...{
		disabled,
		className: `btn ${kind ? MAP_KIND_TO_CLASSNAME[kind] : ''} ${classNames}`,
		type: `button`,
		onClick,
		href,
		target
	}}>
		{label || TRIDION_LABEL_MISSING}
		{children}
	</CustomTag>;
};*/
