import {validatorChainer, isEmail, hasValue} from '../../Form/utils/validators.util';
import FORM from '../../Form/FORM_CONSTANTS';
import {CONSTANTS} from "../../../../CONSTANTS";

const {TEXT} = FORM.INPUT_TYPES;

const {NEWS_LETTER} = CONSTANTS.ROUTING.BACKEND_SERVER;

export default {
    key: 'fb-contactform',
    INPUT_FIELDS: {
        EMAIL: {
            key: 'fb-newsletter-form-email-input',
            label: 'Je mailadres:',
            type: TEXT,
            validator: validatorChainer(hasValue, isEmail),
            get tag() {
                return FORM.inputsWithInputTag.find(input => input === this.type) ? 'input' : this.type;
            }
        }
    },
    SUBMIT: {
        SUBMIT_IS_PENDING_LABEL: 'Verzenden...',
        SUBMIT_LABEL: 'Bevestigen',
        KEY: 'fb-newsletter-form-submit',
        ROUTE: NEWS_LETTER
    },
    get ALL_INPUT_FIELDS() {
        return Object.values(this.INPUT_FIELDS);
    }
};
