import React from 'react';
import Section from "../../../generic-components/components/Sections/Sections";
import Blog from "../../../generic-components/components/Sections/TextAndImage/Blog";
import {getCorrectlySpacedString} from "../../../generic-components/utils/componentTyping.util";

const BlogSection = ({blog, rootHeadingNr, extraClassName}) => {
    const className = getCorrectlySpacedString(extraClassName, 'container', 'fb-blog-section');

    return <Section {...{extraClassName: className}}>
        <Blog {...{
            extraClassName: 'homepage-introduction',
            blog,
            rootHeadingNr
        }}/>
    </Section>;
};

export default BlogSection;
