export const CONSTANTS = {
    HOSTING: {
        WEBSITE: {
            PROD_HOST: 'www.filemonenbaukis.com',
            DEV_HOST: 'localhost:3000',
            DEV_PROTOCOL: 'http://',
            PROD_PROT: 'https://'
        },
        BACKEND_SERVER: {
            DEV_PROTOCOL: 'http://',
            PROD_PROT: 'https://',
            PROD_HOST: 'filemon-en-baukis-backend.herokuapp.com',
            DEV_HOST: 'localhost:5000',
        },
        CONTENT_SERVER: {
            PROD_PROT: 'https://',
            PROD_HOST: 'filemon-en-baukis-content.herokuapp.com',
            get DEV_PROTOCOL() {
                return this.PROD_PROT;
            },
            get DEV_HOST() {
                return this.PROD_HOST;  //TODO: set up dev server
            }
        }
    },
    ROUTING: {
        BACKEND_SERVER: {
            CONTACT_FORM: '/api/send',
            NEWS_LETTER: '/api/subscribe-to-newsletter',
            GDPR_DISCLAIMER: '/disclaimer'
        },
        CONTENT_SERVER: {
            PAGE_LABELS: '/pages',
            FOOTER_LABELS: '/footer',
            NAVIGATION_LABELS: '/navigation'
        }
    },
    ENVIRONMENTS: {
        DEVELOPMENT: 'development',
        PRODUCTION: 'production'
    },
    HARDCODED_PAGES: {
        HOMEPAGE: {
            name: 'homepage',
            id: '5f240775671ffbb7e7cf5998',
            requiredRole: '',    //  TODO
            slug: '/'    //  TODO: delete!!
        },
        MIND_FULNESS: {
            name: 'mindfulness',
            id: '5f240a634e5027268c1d981b',
            slug: '/mindfulness'
        },
        COACHING: {
            name: 'coaching',
            id: '5f4287f84ba3693bd8f52acd',
            slug: '/coaching'
        },
        MEDITATIEF_SCHRIJVEN: {
            name: 'mediatief schrijven',
            id: '5f27061c8c6f8380611411ab',
            slug: '/proprioceptive-writing'
        },
        KOMENDE_ACTIVITEITEN: {
            name: 'komende activiteiten',
            id: '5f5c8c6b1ae6f700176debc6',
            slug: '/komende-activiteiten'
        },
        WIE_BEN_IK: {
            name: 'kennismaking',
            id: '5f3b8d2d55804a0017d498d2',
            slug: '/kennismaking'
        },
        FLYER: {
            name: 'flyer',
            id: '5f6e277cf63f3100175f2b44',
            slug: '/flyer'
        },
        DISCLAIMER: {
            id: '5f836976ec36ab0017406879',
            slug: '/disclaimer'
        }
    },
    get ALL_PAGES() {
        return Object.values(this.HARDCODED_PAGES);
    },
    get FAST_PAGES() {
        const {HOMEPAGE, COACHING, MIND_FULNESS, MEDITATIEF_SCHRIJVEN, WIE_BEN_IK, KOMENDE_ACTIVITEITEN} = this.HARDCODED_PAGES;
        return [HOMEPAGE, COACHING, MIND_FULNESS, MEDITATIEF_SCHRIJVEN, WIE_BEN_IK, KOMENDE_ACTIVITEITEN];
    }
};
