import $ from 'jquery';
import _ from "lodash";


const getPosition = (selector) => $(selector).offset();

const getDimensions = (element, fromRef) => {
	const domElement = fromRef ? element.current : element;
	const $node = $(domElement);

	return {
		width: $node.outerWidth(true),
		height: $node.outerHeight(true),
		innerWidth: $node.innerWidth(),
		innerHeight: $node.innerHeight()
	};
};

const getCursorPosition = e => {

	const changedTouches = e?.changedTouches;
	const mostRecentTouch = changedTouches?.[changedTouches.length - 1];

	return {
		left: e?.clientX || mostRecentTouch?.clientX,
		top: e?.clientY || mostRecentTouch?.clientY,
	}
};

const getSelectorFromClass = className => {
	return `.${className.split(' ').join('.')}`;
};

//getDimensions: outer & inner
const getDimensionChildren = (parentElement, fromRef) => {
	let $parentNode = $(fromRef ? parentElement.current : parentElement);
	let totalWidth = 0;
	let totalHeight = 0;
	let totalInnerHeight = 0;
	let totalInnerWidth = 0;

	const children = $parentNode.children();
	_.forEach(children, child => {
		const widthToAdd = getDimensions($(child)).width;
		const heightToAdd = getDimensions($(child)).height;
		const innerHeightToAdd = getDimensions($(child)).innerHeight;
		const innerWidthToAdd = getDimensions($(child)).innerWidth;

		totalWidth += widthToAdd;
		totalHeight += heightToAdd;
		totalInnerHeight += innerHeightToAdd;
		totalInnerWidth += innerWidthToAdd;

	});


	return {width: totalWidth, height: totalHeight, innerWidth: totalInnerWidth, innerHeight: totalInnerHeight};
};

export default {
	getPosition,
	getDimensions,
	getCursorPosition,
	getSelectorFromClass,
	getDimensionChildren
}
