import {CONSTANTS} from "../CONSTANTS";

export const getCurrentPage = () => {
    const currentRoute = window.location.pathname;
    console.log('currentRoute', currentRoute);
    return CONSTANTS.ALL_PAGES.find(PAGE => PAGE.slug === currentRoute);
};

export const getWebsiteUrl = (appEnv, route) => {
    const {ENVIRONMENT, HOSTING} = CONSTANTS;
    const {WEBSITE, DEV_PROTOCOL, PROD_PROT} = HOSTING;
    const {PROD_HOST, DEV_HOST} = WEBSITE;
    const {DEVELOPMENT} = ENVIRONMENT;

    const fullDomain = appEnv === DEVELOPMENT ? DEV_PROTOCOL + DEV_HOST : PROD_PROT + PROD_HOST;
    return `${fullDomain}${route}`;
}

export const getContentServerUrl = (appEnv, route) => {
    const {ENVIRONMENTS, HOSTING} = CONSTANTS;
    const {CONTENT_SERVER} = HOSTING;
    const {PROD_HOST, DEV_HOST, DEV_PROTOCOL, PROD_PROT} = CONTENT_SERVER;
    const {DEVELOPMENT} = ENVIRONMENTS;

    const fullDomain = appEnv === DEVELOPMENT ? (DEV_PROTOCOL + DEV_HOST) : (PROD_PROT + PROD_HOST);
    return `${fullDomain}${route}`;
}

export const getBackendServerUrl = (appEnv, route) => {
    const {ENVIRONMENTS, HOSTING} = CONSTANTS;
    const {BACKEND_SERVER} = HOSTING;
    const {PROD_HOST, DEV_HOST, DEV_PROTOCOL, PROD_PROT} = BACKEND_SERVER;
    const {DEVELOPMENT} = ENVIRONMENTS;

    const fullDomain = appEnv === DEVELOPMENT ? DEV_PROTOCOL + DEV_HOST : PROD_PROT + PROD_HOST;
    return `${fullDomain}${route}`;
}

