import _ from 'lodash';

export const reduceNavigationTabs = (newNavigation, navigationTabsLabels) => {

    const newTabs = newNavigation.tabs = [];

    _.forEach(navigationTabsLabels, tabLabels => {
        const page = tabLabels?.page;
        newTabs.push({
            title: tabLabels?.title,
            slug: tabLabels?.page?.slug,
            isPagePublished: page?.isPublished,
            pageId: page?.id
        });
    });
};

export const setNavigationLabels = (state, payload) => {
    const newState = _.cloneDeep(state) || {};
    const navigationLabels = payload?.data;
    const newNavigation = newState.navigation = {};

    reduceNavigationTabs(newNavigation, navigationLabels?.navigationTabs);

    return newState;
};

export const activateNavigationTab = (state, payload) => {
    const newState = _.cloneDeep(state);

    const newTabs = newState?.navigation?.tabs;

    const newPreviousActiveTab = _.find(newTabs, tab => tab?.isActive) || {};
    const currentActivePageId = payload?.pageId;
    const newCurrentActiveTab = _.find(newTabs, tab => tab?.pageId === currentActivePageId) || {};

    newCurrentActiveTab.isActive = true;
    const activeTabIsClickedAgain = currentActivePageId === newPreviousActiveTab?.pageId;

    newPreviousActiveTab.isActive = activeTabIsClickedAgain && currentActivePageId !== -1;

    return newState;
};

